



.custom-btn {
  background-color: #960606;
  border-color: #960606;
  color: black;
  border-radius: 3px;
  padding: 5px;
}
.custom-btn:hover {
  background-color: #750505;
  border-color: #750505;
}

.services-btn {
  border-color: #960606;
  color: white;
  border-radius: 3px;
  padding: 5px;
}

.phylee-primary-btn {
  background-color: #960606;
  border-color: #ffffff;
  color: white;
  border-radius: 3px;
  padding: 10px;
  width: 250px;
  
}
.phylee-primary-btn:hover {
  background-color: #763c3c;
  border-color: #e6e3e3;
}



.custom-btn2 {
  background-color: #00007e;
  border-color: #00007e;
  color: white;
  border-radius: 3px;
  padding: 5px;
}
.custom-btn2:hover {
  background-color: #010154;
  border-color: #010154;
}


.website-red{
  background: #a71930;

}
.website-red{
  background: #a71930;
  margin: 10px;
  padding: 20px;
  color: white;
  border-radius: 5px;
  font-weight: bolder;
  box-shadow: black 3px 3px 3px;
}

.website-blue{
  background: #232c57;
  margin: 10px;
  padding: 20px;
  color: white;
  border-radius: 5px;
  font-weight: bolder;
  box-shadow: black 3px 3px 3px;
  
}

.slide-p-size {
  background: #a71930;
  padding: 10px;
  
}


.newsletter {
  width: 300px;
  border-radius: 10px;
}

form button {
  border-radius: 10px;
  margin-left: 5px;
}


header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("./images/aboutus.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; 
  height: 600px; 
  width: 100%; 
  margin-bottom: 20px; 
  color: white;
}


.header-action {
  border-radius: 20px;
  width: 100%; 
  height: 60px;
  display: flex;
  align-items: center;
  font-size: 0.8rem;

}



@media (min-width: 992px) {
  .header-action {
    width: 50%;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .custom-caption {
    top: 20%;
    left: 5%;
  }

  .custom-caption h1 {
    font-size: 2.5rem; 
  }

  .custom-caption p {
    font-size: 0.9rem; /* Smaller text */
  }


  .header-action-btn,
  .btn-dark {
    width: 100%;
    margin-bottom: 10px; /* Separate buttons */
  }
}




/* Hide on larger screens */
.travel-advice-container {
  display: none; /* Hide by default */
}

/* Show only on smaller screens */
@media (max-width: 768px) {
  .travel-advice-container {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    text-align: center;
  }

  .travel-advice-text {
    margin-bottom: 15px; /* Add space between the text and button */
    font-size: 1.2rem; /* Optional: adjust font size */
  }

  .book-now-btn {
    width: auto; /* Adjust button width if needed */
  }

  
}













.page-title {
  padding: 20px;
  margin: 70px;
  text-align: center;
  color: white;

}

.justina {
  text-align: justify;
}

.bold {
  font-weight: bolder;
}



.home-about-component {
  background: #232c57;
  border-radius: 10px;
  padding: 20px;
  color: white;
  font-size: 1.3rem;
  font-weight: bold;
}

.home-about-photo{
  background-image: url("/src/images/about-us.jpg");
  background-repeat: no-repeat;
  background-position: center;
}


.nav-link-hover:hover {
  color: #fff; /* Change text color on hover */
  text-decoration: none; /* Remove underline on hover */
  background: rgb(130, 130, 242);
}

/* auth form style */
.auth-container {
  display: flex;
  justify-content: center;
}

.auth-form {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  max-width: 400px;
}

.auth-form h2 {
  margin-bottom: 20px;
}

.auth-form input {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.auth-form button {
  width: 100%;
  padding: 10px;
  background-color: #232c57;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.auth-form button:hover {
  background-color: #0056b3;
}



/* dashboard styling area */
.side-bar {
  font-size: 1rem;
  padding: 1rem;
  border-right: 1px dotted #0056b3;
 
}


ul {
  list-style-type: none;
  padding: 10px;
  margin-top: 10px;
  
}

li {
  margin-bottom: 1rem;
}

li a {
  text-decoration: none;
}

li:hover {
  border-bottom: 2px #9e070c solid;
}


.course-image {
  width: 310px; /* Adjust according to your design */
  height: 163px; /* Adjust according to your design */
  object-fit: cover; /* Ensures the image covers the entire container */
}


/* Home Dashboard.css */

.icon {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #007bff;
}

.card {
  transition: transform 0.2s;
}

.card:hover {
  transform: translateY(-5px);
}





/* custom NAVBAR CSS  */


/* General Navbar Styling */
.navbar-logo {
  width: 105px;
  height: auto;
  transition: width 0.3s ease;
}

.navbar-toggler {
  border: none;
}

/* Small Screens (Tablets & Mobile) */
@media (max-width: 992px) {
  .navbar-collapse {
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 126, 0.9);
    padding: 10px 0;
  }

  .navbar-nav {
    flex-direction: column;
    align-items: center;
  }

  .nav-item {
    margin: 5px 0;
  }

  .navbar-logo {
    width: 90px;
  }
}

/* Extra Small Screens (Mobile) */
@media (max-width: 768px) {
  .navbar-collapse {
    background-color: rgba(0, 0, 126, 1);
  }

  .navbar-logo {
    width: 80px;
  }
}



/* explore couresel section *//* explore carousel section */


.carousel-container {
  text-align: center;
  margin: 40px auto;
  width: 80%;
}


.carousel-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.desc {
  background-color: #555;
  padding: 5px;
  border-radius: 10px;
}

.carousel-subtitle {
  font-size: 1.1rem;
  color: gray;
  margin-bottom: 20px;
}

.carousel-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  
}

.carousel-control {
  font-size: 1.5rem;
  border: none;
  background: transparent;
  color: black;
  padding: 0 20px;
  cursor: pointer;
  z-index: 1;
}

.carousel-cards {
  display: flex;
  gap: 20px;
  overflow: hidden;
  flex: 1;
  justify-content: center;
}

.carousel-card {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.carousel-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.carousel-caption-card {
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border-radius: 8px;
  color: black;
  font-size: 0.9rem;
  text-align: left;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .carousel-container {
    width: 90%;
  }
  .carousel-cards {
    flex-direction: column; 
    align-items: center;
  }

  .carousel-card {
    width: 100%; /* Full width for stacking */
    margin-bottom: 20px; /* Add margin between cards */
  }

  /* Modify buttons to fit better on small screens */
  .carousel-control {
    font-size: 1.2rem; /* Reduce the button size */
    padding: 10px; /* Add padding for a better touch target */
  }
  
  .carousel-cards {
    flex-direction: column; 
    align-items: center;
  }

  .carousel-card {
    width: 100%; /* Full width for stacking */
  }

  .carousel-title {
    font-size: 1.5rem;
  }
  
  .carousel-subtitle {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .carousel-container {
    width: 95%;
  }

  .carousel-title {
    font-size: 1.25rem;
  }
  
  .carousel-subtitle {
    font-size: 0.9rem;
  }

  
}


/* study abroad couresel section *//* explore carousel section */
.studyabroad-background-container {
  height: 650px;
}

.study-carousel-container {
  position: relative;
  text-align: center;
  margin: 40px auto;
  width: 100%;
  height: 500px; 
  background-image: url('./images/studyabroad-bg.png'); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.study-carousel-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 20px); /* Center horizontally and move down */
}

.study-carousel-container .carousel-card {
  width: 250px;
  background-color: white;
  text-align: left;
  padding: 15px;
}

.studyabroad-carousel-card {
  border: 0.5px solid #0056b3;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3); 
}

.study-carousel-container .carousel-card img {
  display: block;
  margin-bottom: 10px;
}

.study-carousel-container .carousel-card h2 {
  font-size: 1.2rem;
  margin-bottom: 8px;
  text-align: left;
}

.study-carousel-container .carousel-card p {
  font-size: 0.7rem;
  color: #555;
}

.learn-more-link {
  color: #000000;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  margin-top: 8px;
}

.learn-more-link:hover {
  text-decoration: underline;
}

.learn-more-link svg {
  margin-left: 5px;
}

.studyabroad-carousel-title {
  color: white;
  margin-top: 40px;
}



.carousel-title {
  font-size: 2rem; /* Adjust as needed */
  margin-bottom: 1rem;
}

.studyabroad-subtitle {
  font-size: 1.2rem; /* Adjust as needed */
  line-height: 1.5;
  margin-bottom: 1rem;
}



/* testimonial style area */

.testimonials-carousel-container{
  margin-bottom: 50px;
}

.testimonials-carousel-card {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.3);


}

.testimonial-subtitle{
  font-size: 0.8rem;
  width: 30%;
  margin-top: 30px;
}

.testimonials-carousel-card h2{
font-size: 1rem;
margin-top: 10px;
}

.testimonials-description{
  font-size: 0.8rem;
}

/* sunscription banner style section */

.subscription-banner {
  background-image: url('./images/subscriptionbackground.png'); ;
  background-size: cover;
  background-position: center;
  padding: 80px 0;
  color: #fff;
  text-align: center;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.footer-image {
  background-color: #0056b3;
  border-radius: 10px;
  width: 60%;
}

.footer-text {
  margin-top: 10px;
  font-size: 0.8rem;
  width: 70%;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.scroll-to-top div {
  background-color: #0056b3;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white; 
}


/* about us area */

.stats-section {
  background-image: url("./images/stats-sections.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 300px;
  display: flex; 
  align-items: center; 
  justify-content: center; 
  text-align: center; 
}


.contact-us-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("./images/contact-us.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; 
  height: 600px; 
  width: 100%; 
  margin-bottom: 20px; 
  color: white;
}


.packages-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("./images/packages.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; 
  height: 600px; 
  width: 100%; 
  margin-bottom: 20px; 
  color: white;
}
.blog-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("./images/blog.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; 
  height: 600px; 
  width: 100%; 
  margin-bottom: 20px; 
  color: white;
}

